// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require('jquery')

Rails.start()
Turbolinks.start()
ActiveStorage.start()

jQuery(document).on('ready turbolinks:load', function() {

  console.log('Test');

  jQuery('.global_header__toggle').click(function(e){
    e.preventDefault();
    jQuery(this).toggleClass('global_header__toggle--active');
    jQuery('#global_header__programme_list').slideToggle();
    jQuery('.global_header__title').fadeToggle();
  });

});